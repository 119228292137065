










































































































































































































































































































import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default defineComponent({
  name: 'GoodHomeworkPhone',
  components: {
    swiper,
    swiperSlide
  },
  setup() {
    const showState = reactive({
      gzlys: false,
      gzlzy: false
    })
    const handleSetShow = (type: string) => {
      showState[type] = true
    }
    return {
      ...toRefs(showState),
      handleSetShow,
      swiperOption: {
        pagination: { el: '.swiper-pagination' },
        autoplay: false
      }
    }
  }
})
